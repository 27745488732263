.loading {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.loader {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  box-sizing: border-box;
  border: solid 5px rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
